/* Main container */
.report-generator {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Form controls container */
.report-controls {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Client selection section */
.select-client {
  width: 100%;
}

.select-client label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #333;
}

/* Search parameters section */
.search-params {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  width: 100%;
}

.radius-control,
.time-control {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.radius-control label,
.time-control label {
  font-weight: 600;
  color: #333;
}

.time-control {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.time-control input[type="number"] {
  width: 60px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.time-control select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  min-width: 80px;
}

.time-control input[type="number"]::-webkit-inner-spin-button,
.time-control input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

.time-control span {
  color: #333;
  font-size: 14px;
}

.time-input-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.time-input-group input[type="number"] {
  width: 60px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.time-unit-btn {
  padding: 8px 16px;
  border: 1px solid #ddd;
  background-color: #f5f5f5;
  color: #666;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.time-unit-btn.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.time-unit-btn:hover:not(.active) {
  background-color: #e9ecef;
}

.time-input-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.number-input {
  position: relative;
  width: 80px;
}

.number-input input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  appearance: none;
  -moz-appearance: textfield;
  text-align: center;
}

.number-input input::-webkit-outer-spin-button,
.number-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.segmented-control {
  display: flex;
  background-color: #f0f0f0;
  padding: 3px;
  border-radius: 6px;
  gap: 3px;
}


/* Date range section */
.date-range {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.date-range label {
  font-weight: 600;
  color: #333;
}

.date-range-inputs {
  display: flex;
  gap: 15px;
}

/* Form inputs */
input[type="text"],
input[type="date"],
select {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
}

select {
  background-color: white;
}




/* Reports section */
.reports-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.report-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px;
}

.report-card h3 {
  margin-top: 0;
  color: #333;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .report-controls {
    gap: 15px;
  }

  .search-params {
    grid-template-columns: 1fr;
  }

  .date-range-inputs {
    flex-direction: column;
  }

  .reports-section {
    grid-template-columns: 1fr;
  }

  .time-input-wrapper {
    flex-direction: column;
    align-items: stretch;
  }

  .number-input {
    width: 100%;
  }

  .segmented-control {
    width: 100%;
  }

  .segmented-control button {
    flex: 1;
    padding: 10px;
  }
}

/* Add subtle touch feedback for mobile */
@media (hover: none) {
  .segmented-control button:active {
    background-color: rgba(0, 123, 255, 0.1);
  }
}
