.report-generator {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.report-controls {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  align-items: center;
}

.select-client, .date-range {
  display: flex;
  gap: 10px;
  align-items: center;
}

.reports-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.report-card {
  border: 1px solid #e5e7eb;
  padding: 15px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.report-card h3 {
  color: #111827;
  margin-bottom: 0; /* Changed from 4px to 0 to remove gap */
  font-weight: 600; /* Make address consistently bold */
}

.location-group {
  border-bottom: 2px solid #fde4a6; /* Move border to group instead of h3 */
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.location-group h4 {
  color: #4b5563; /* Slightly muted color for city/state */
  margin-top: 0;
  line-height: 1.2; /* Add this to control spacing */
}

.report-stats {
  margin: 15px 0;
}

.events-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.events-table th,
.events-table td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

.events-table th {
  background-color: #fde4a6;
  color: black;
}

.events-table tr:hover {
  background-color: #fff8e6;
}

.search-params {
  display: flex;
  gap: 20px;
  align-items: center;
}

.radius-control,
.years-control {
  display: flex;
  gap: 10px;
  align-items: center;
}

.radius-control select,
.years-control select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.time-control {
  display: flex;
  gap: 10px;
  align-items: center;
}

.time-control select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.autocomplete-wrapper {
  position: relative;
  width: 300px;
}

.autocomplete-wrapper input {
  width: 100%;
  padding: 8px;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.2s;
}

.autocomplete-wrapper input:focus {
  border-color: #f8b503;
  outline: none;
  ring: 2px #fde4a6;
}

.suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 4px 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.suggestion-item {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.suggestion-item:hover {
  background-color: #fde4a6;
}

.select-client {
  position: relative;
  z-index: 100;
}

.sources-control {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.source-checkboxes {
  display: flex;
  gap: 15px;
}

.source-checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.source-checkbox input[type="checkbox"] {
  cursor: pointer;
}

#lookback-control {
  display: flex;
  border: 1px solid #f8b503;
  border-radius: 4px;
  overflow: hidden;
  background-color: #fff;
}

#lookback-control button {
  flex: 1;
  padding: 5px 15px;
  border: none;
  background-color: #fde4a6;
  cursor: pointer;
  transition: all 0.2s;
  color: black;
}

#lookback-control button[class*="bg-primary"] {
  background-color: #f8b503 !important;
}

#lookback-control button[class*="bg-primary-light"] {
  background-color: #fde4a6 !important;
}

#lookback-control button:not(:last-child) {
  border-right: 1px solid #ddd;
}

#lookback-control button:hover {
  background-color: #f8b503;
}

.time-input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.number-input input {
  width: 60px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
}

.number-input input:focus {
  outline: none;
  border-color: #f8b503;
}

.button-group {
  display: flex;
  gap: 10px;
  align-items: center;
}

/* Update button styles to match theme */
.button-group button {
  padding: 10px 20px;
  background-color: #fde4a6;
  color: black;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  transition: background-color 0.2s;
  cursor: pointer;
}

.button-group button:hover {
  background-color: #f8b503;
}

.button-group button:disabled {
  background-color: #e5e7eb;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Remove old button styles */
.button-group .download-button,
.button-group a.download-button,
.button-group .download-button:hover,
.button-group a.download-button:hover {
  /* Remove these style blocks entirely */
}
