.search-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.search-form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.search-form .form-group label {
  display: block !important;
  width: 100% !important;
  margin-bottom: 8px !important;
  font-weight: bold !important;
}

.form-control {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.radio-label {
  display: flex;
  align-items: center;
  gap: 8px;
}

.address-section {
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.address-input {
  font-size: 16px;
  padding: 12px;
  width: 100%;
  padding-right: 40px !important; /* Make room for the icon */
}

.search-button {
  width: 100%;
  padding: 12px;
  background-color: #f8b503;
  color: #000000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.search-button:disabled {
  background-color: #fde4a6;
}

.autocomplete-wrapper {
  position: relative;
  flex: 1;
}

.toggle-switch {
  position: absolute;
  right: 10px;
  top: 43%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-switch:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.toggle-switch.active {
  color: #f8b503;
}

.lightning-icon {
  font-size: 18px;
}

.suggestions-list {
  position: absolute;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.search-options {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  margin-bottom: 20px;
}

.checkbox-group {
  margin-top: 8px;
}

.input-row {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Remove the old auto-checkbox styles since we don't need them anymore */
.auto-checkbox {
  display: none;
}

.auto-checkbox input[type="checkbox"] {
  margin: 0;
}

.auto-checkbox label {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
}

@media (max-width: 768px) {
  .search-form-grid {
    grid-template-columns: 1fr;
  }

  .search-options {
    grid-template-columns: 1fr;
  }
}
