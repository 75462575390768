/* Mobile-specific styles */
@media (max-width: 320px) {
  thead {
    display: none;
  }

  table, thead, tbody, th, td, tr {
    display: block;
    min-width: 320px;
  }

  td {
    position: relative;
    padding-left: 50%;
    text-align: left;
  }

  td:before {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    content: attr(data-label);
    font-weight: bold;
  }

  tr {
    margin-bottom: 10px;
  }
}