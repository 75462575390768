.admin-config {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.config-section {
  margin: 20px 0;
  padding: 15px;
  background-color: #f9fafb;
  border-radius: 4px;
}

.config-section label {
  display: block;
  margin-bottom: 10px;
}

.config-section input {
  margin-left: 10px;
  padding: 5px;
  width: 80px;
}

.config-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.config-table th,
.config-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

.config-table th {
  background-color: #f5f5f5;
}

.config-table input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.message {
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  background-color: #f0f9ff;
  border: 1px solid #bae6fd;
  white-space: pre-wrap;
  line-height: 1.5;
}

.admin-actions {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9fafb;
  border-radius: 4px;
}

.reset-button {
  background-color: #dc2626;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.reset-button:hover {
  background-color: #b91c1c;
}

.function-button {
  background-color: #2563eb;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.function-button:hover {
  background-color: #1d4ed8;
}

.function-button:disabled {
  background-color: #93c5fd;
  cursor: not-allowed;
}

.function-button:last-child {
  background-color: #059669;
}

.function-button:last-child:hover {
  background-color: #047857;
}

.function-results {
  margin-top: 30px;
}

.results-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.results-table th,
.results-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

.results-table th {
  background-color: #e5e7eb;
}

.results-table tbody tr:nth-child(even) {
  background-color: #f9fafb;
}

.results-section {
  margin-top: 30px;
  padding: 20px;
  background-color: #f9fafb;
  border-radius: 4px;
}

.result-card {
  background-color: white;
  padding: 15px;
  margin: 10px 0;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.result-card h4 {
  margin: 0 0 10px 0;
  color: #2563eb;
}

.report-summary {
  margin-top: 10px;
}

.report-summary ul {
  list-style: none;
  padding-left: 0;
  margin: 10px 0;
}

.report-summary li {
  margin: 5px 0;
  color: #4b5563;
}

/* Remove these email-related classes */
.email-section,
.email-input {
  display: none;
}

.test-button {
  background-color: #6366f1 !important;
}

.test-button:hover {
  background-color: #4f46e5 !important;
}

.test-button:disabled {
  background-color: #c7d2fe !important;
}